var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h4',{staticClass:"text-h6 primary500--text font-weight-bold"},[_vm._v(" Site statistics ")]),_c('p',{staticClass:"text--secondary text-body-2"},[_vm._v(" All times are server time (UTC), timezones are "),_c('b',[_vm._v("not")]),_vm._v(" converted. UTC time now: "+_vm._s(new Date().toLocaleString('default', {timeStyle: 'short', timeZone: 'UTC'}))+" ")]),_c('div',{staticClass:"heading d-flex align-center mb-4"},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","right":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"color":"grey300","tile":"","ripple":false}},on),[_vm._v(" "+_vm._s(_vm.form.range[0])+" "),_c('span',{staticClass:"text--secondary mx-2"},[_vm._v("–")]),_vm._v(" "+_vm._s(_vm.form.range[1])+" ")])]}}]),model:{value:(_vm.datepicker),callback:function ($$v) {_vm.datepicker=$$v},expression:"datepicker"}},[_c('v-date-picker',{attrs:{"color":"primary500","scrollable":"","no-title":"","show-adjacent-months":"","range":""},model:{value:(_vm.form.range),callback:function ($$v) {_vm.$set(_vm.form, "range", $$v)},expression:"form.range"}})],1),_c('v-select',{staticClass:"rounded-0 grouping-selector mr-2",attrs:{"items":['hour', 'day', 'week', 'month', 'year'],"hide-details":"","solo":"","flat":"","dense":"","background-color":"grey300","label":"Grouping"},model:{value:(_vm.form.groupBy),callback:function ($$v) {_vm.$set(_vm.form, "groupBy", $$v)},expression:"form.groupBy"}}),_c('v-btn',{attrs:{"color":"blue700","tile":"","ripple":false,"loading":_vm.loading.get},on:{"click":_vm.getStats}},[_c('fai',{staticClass:"blue500--text mr-2",attrs:{"icon":['fas','sync']}}),_vm._v(" Get stats ")],1),_c('v-spacer')],1),_c('v-row',{staticClass:"mb-3"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-sheet',{attrs:{"color":"grey800"}},[_c('h5',{staticClass:"text-body-1 lh-1 green700 green200--text pa-4"},[_vm._v(" Bankroll distribution & total ")]),_c('BarChart',{staticClass:"grey600 pa-2",attrs:{"chart-data":{
            labels: _vm.labels,
            datasets: [
              Object.assign({}, _vm.chartDataUserBalances, {type: 'line'}),
              Object.assign({}, _vm.chartDataUserItems, {type: 'line'}),
              Object.assign({}, _vm.chartDataAffiliateBalances, {type: 'line'}),
              Object.assign({}, _vm.chartDataBankTotal, {type: 'line'}) ].concat( _vm.chartDataBankSpread )
          },"options":Object.assign({}, _vm.chartOptions,
            {scales: {
              xAxes: [{ stacked: true, }],
            }})}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-sheet',{attrs:{"color":"grey800"}},[_c('h5',{staticClass:"text-body-1 lh-1 green700 green200--text pa-4"},[_vm._v(" Bankroll distribution (latest) ")]),_c('PieChart',{staticClass:"grey600 pa-2",attrs:{"chart-data":_vm.chartDataBankSpreadPie,"options":_vm.chartOptions}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-sheet',{attrs:{"color":"grey800"}},[_c('h5',{staticClass:"text-body-1 lh-1 blue700 blue200--text pa-4"},[_vm._v(" Deposits vs Withdraws ($) ")]),_c('BarChart',{staticClass:"grey600 pa-2",attrs:{"chart-data":{
            labels: _vm.labels,
            datasets: _vm.chartDataDepositWithdrawCount.concat( _vm.chartDataDepositWithdrawValue.map(function (i) { return (Object.assign({}, i, {fill: false, type: 'line'})); }) )
          },"options":_vm.chartOptions}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-sheet',{attrs:{"color":"grey800"}},[_c('h5',{staticClass:"text-body-1 lh-1 blue700 blue200--text pa-4"},[_vm._v(" Deposits by method ($) ")]),_c('BarChart',{staticClass:"grey600 pa-2",attrs:{"chart-data":{
            labels: _vm.labels,
            datasets: [].concat( _vm.chartDataDepositsSpread )
          },"options":_vm.chartOptions}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-sheet',{attrs:{"color":"grey800"}},[_c('h5',{staticClass:"text-body-1 lh-1 green700 green200--text pa-4"},[_vm._v(" Free money claimed ($) ")]),_c('BarChart',{staticClass:"grey600 pa-2",attrs:{"chart-data":{
            labels: _vm.labels,
            datasets: [].concat( _vm.chartDataFreeMoneySum.map(function (i) { return (Object.assign({}, i, {fill: false, type: 'line'})); }) )
          },"options":_vm.chartOptions}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-sheet',{attrs:{"color":"grey800"}},[_c('h5',{staticClass:"text-body-1 lh-1 grey300 grey100--text pa-4 d-flex align-center"},[_vm._v(" Game bets ($) "),_c('v-spacer'),_c('span',{staticClass:"text-body-2 lh-1"},[_c('span',{attrs:{"title":"Average game profits in this timespan"}},[_vm._v("$"+_vm._s(_vm._f("toCurrency")(_vm.avgGameProfits))+" avg profit per "+_vm._s(_vm.form.groupBy))]),_c('span',{staticClass:"mx-3 faded-8"},[_vm._v("·")]),_c('span',{attrs:{"title":"Actual house edge in this timespan"}},[_vm._v(_vm._s(_vm._f("toCurrency")(_vm.avgGameProfitPerc * 100))+"% edge")])])],1),_c('BarChart',{staticClass:"grey600 pa-2",attrs:{"chart-data":{
            labels: _vm.labels,
            datasets: [
              _vm.chartDataBetsCount ].concat( _vm.chartDataBetsTotals.map(function (i) { return (Object.assign({}, i, {fill: false, type: 'line'})); }) )
          },"options":_vm.chartOptions}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-sheet',{attrs:{"color":"grey800"}},[_c('h5',{staticClass:"text-body-1 lh-1 grey300 grey100--text pa-4 d-flex align-center"},[_vm._v(" Bet sum by game ")]),_c('BarChart',{staticClass:"grey600 pa-2",attrs:{"chart-data":{
            labels: _vm.labels,
            datasets: [].concat( _vm.chartDataBetsSpread )
          },"options":_vm.chartOptions}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-sheet',{attrs:{"color":"grey800"}},[_c('h5',{staticClass:"text-body-1 lh-1 grey300 grey100--text pa-4"},[_vm._v(" New users & online count ")]),_c('LineChart',{staticClass:"grey600 pa-2",attrs:{"chart-data":{ labels: _vm.labels, datasets: [].concat( _vm.chartDataNewUsersCount ) },"options":_vm.chartOptions}})],1)],1)],1),_c('v-expansion-panels',{attrs:{"accordion":"","flat":"","tile":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"color":"grey600"}},[_vm._v(" Raw stats data ")]),_c('v-expansion-panel-content',{attrs:{"color":"grey600"}},[_c('div',{staticClass:"d-flex justify-end mb-3"},[_c('v-btn',{staticClass:"rounded-0",attrs:{"color":"blue700 blue300--text","depressed":"","ripple":false,"x-small":""},on:{"click":function($event){_vm.onCopy(JSON.stringify(_vm.stats))}}},[_vm._v(" Copy JSON ")])],1),_c('pre',{staticClass:"text-caption",domProps:{"textContent":_vm._s(_vm.stats)}})])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }