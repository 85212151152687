<template>
  <div>
    <h4 class="text-h6 primary500--text font-weight-bold">
      Site statistics
    </h4>
    <p class="text--secondary text-body-2">
      All times are server time (UTC), timezones are <b>not</b> converted.
      UTC time now: {{ new Date().toLocaleString('default', {timeStyle: 'short', timeZone: 'UTC'}) }}
    </p>

    <div class="heading d-flex align-center mb-4">
      <v-menu
        v-model="datepicker"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y right min-width="290px"
      >
        <template #activator="{ on }">
          <v-btn
            color="grey300" class="mr-2"
            tile :ripple="false" v-on="on"
          >
            {{ form.range[0] }}
            <span class="text--secondary mx-2">&ndash;</span>
            {{ form.range[1] }}
          </v-btn>
        </template>
        <v-date-picker
          v-model="form.range"
          color="primary500"
          scrollable
          no-title
          show-adjacent-months
          range
        />
      </v-menu>

      <v-select
        v-model="form.groupBy"
        :items="['hour', 'day', 'week', 'month', 'year']"
        hide-details class="rounded-0 grouping-selector mr-2"
        solo flat dense background-color="grey300"
        label="Grouping"
      />


      <v-btn
        color="blue700" tile
        :ripple="false" :loading="loading.get"
        @click="getStats"
      >
        <fai class="blue500--text mr-2" :icon="['fas','sync']" />
        Get stats
      </v-btn>

      <v-spacer />
    </div>


    <!-- <LineChart v-if="stats" :chart-data="bankData.data" :options="bankData.options" /> -->

    <v-row class="mb-3">
      <v-col cols="12">
        <v-sheet color="grey800">
          <h5 class="text-body-1 lh-1 green700 green200--text pa-4">
            Bankroll distribution &amp; total
          </h5>
          <BarChart
            class="grey600 pa-2"
            :chart-data="{
              labels,
              datasets: [
                {...chartDataUserBalances, type: 'line' },
                {...chartDataUserItems, type: 'line' },
                {...chartDataAffiliateBalances, type: 'line' },
                {...chartDataBankTotal, type: 'line' },
                ...chartDataBankSpread,
              ]
            }"
            :options="{
              ...chartOptions,
              scales: {
                xAxes: [{ stacked: true, }],
              }
            }"
          />
        </v-sheet>
      </v-col>

      <v-col cols="12">
        <v-sheet color="grey800">
          <h5 class="text-body-1 lh-1 green700 green200--text pa-4">
            Bankroll distribution (latest)
          </h5>

          <PieChart
            class="grey600 pa-2"
            :chart-data="chartDataBankSpreadPie"
            :options="chartOptions"
          />
        </v-sheet>
      </v-col>

      <v-col cols="12">
        <v-sheet color="grey800">
          <h5 class="text-body-1 lh-1 blue700 blue200--text pa-4">
            Deposits vs Withdraws ($)
          </h5>

          <BarChart
            class="grey600 pa-2"
            :chart-data="{
              labels,
              datasets: [
                ...chartDataDepositWithdrawCount,
                ...chartDataDepositWithdrawValue.map(i => ({...i, fill: false, type: 'line'})),
              ]
            }"
            :options="chartOptions"
          />
        </v-sheet>
      </v-col>

      <v-col cols="12">
        <v-sheet color="grey800">
          <h5 class="text-body-1 lh-1 blue700 blue200--text pa-4">
            Deposits by method ($)
          </h5>

          <BarChart
            class="grey600 pa-2"
            :chart-data="{
              labels,
              datasets: [
                ...chartDataDepositsSpread,
              ]
            }"
            :options="chartOptions"
          />
        </v-sheet>
      </v-col>

      <v-col cols="12">
        <v-sheet color="grey800">
          <h5 class="text-body-1 lh-1 green700 green200--text pa-4">
            Free money claimed ($)
          </h5>

          <BarChart
            class="grey600 pa-2"
            :chart-data="{
              labels,
              datasets: [
                ...chartDataFreeMoneySum.map(i => ({...i, fill: false, type: 'line'})),
              ]
            }"
            :options="chartOptions"
          />
        </v-sheet>
      </v-col>

      <v-col cols="12">
        <v-sheet color="grey800">
          <h5 class="text-body-1 lh-1 grey300 grey100--text pa-4 d-flex align-center">
            Game bets ($)
            <v-spacer />
            <span class="text-body-2 lh-1">
              <span title="Average game profits in this timespan">${{ avgGameProfits | toCurrency }} avg profit per {{ form.groupBy }}</span>
              <span class="mx-3 faded-8">&middot;</span>
              <span title="Actual house edge in this timespan">{{ avgGameProfitPerc * 100 | toCurrency }}% edge</span>
            </span>
          </h5>

          <BarChart
            class="grey600 pa-2"
            :chart-data="{
              labels,
              datasets: [
                chartDataBetsCount,
                ...chartDataBetsTotals.map(i => ({...i, fill: false, type: 'line'})),
              ]
            }"
            :options="chartOptions"
          />
        </v-sheet>
      </v-col>

      <v-col cols="12">
        <v-sheet color="grey800">
          <h5 class="text-body-1 lh-1 grey300 grey100--text pa-4 d-flex align-center">
            Bet sum by game
          </h5>

          <BarChart
            class="grey600 pa-2"
            :chart-data="{
              labels,
              datasets: [
                ...chartDataBetsSpread,
              ]
            }"
            :options="chartOptions"
          />
        </v-sheet>
      </v-col>

      <v-col cols="12">
        <v-sheet color="grey800">
          <h5 class="text-body-1 lh-1 grey300 grey100--text pa-4">
            New users &amp; online count
          </h5>

          <LineChart
            class="grey600 pa-2"
            :chart-data="{ labels, datasets: [...chartDataNewUsersCount] }"
            :options="chartOptions"
          />
        </v-sheet>
      </v-col>
    </v-row>


    <v-expansion-panels accordion flat tile>
      <v-expansion-panel>
        <v-expansion-panel-header color="grey600">
          Raw stats data
        </v-expansion-panel-header>
        <v-expansion-panel-content color="grey600">
          <div class="d-flex justify-end mb-3">
            <v-btn
              color="blue700 blue300--text"
              class="rounded-0"

              depressed :ripple="false" x-small
              @click="onCopy(JSON.stringify(stats))"
            >
              Copy JSON
            </v-btn>
          </div>
          <pre class="text-caption" v-text="stats" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import sortBy from 'lodash.sortby'

import BarChart from '@/components/BarChart'
import PieChart from '@/components/PieChart'
import LineChart from '@/components/LineChart'

const COLORS = [
  '#c0392b', // red
  '#3dc1d3', // blue
  '#f78fb3', // pink
  '#27ae60', // dark green
  '#d35400', // orange
  '#2c3e50', // dark grey
  '#8e44ad', // purple
  '#1abc9c', // cyan
  '#7f8c8d', // light grey
  '#f39c12', // orange
  '#16a085', // light green
]

const d = new Date()
const STARTING_DATE = d.setDate(d.getDate() - 7)

export default {
  components: { LineChart, BarChart, PieChart },
  data() {
    return {
      loading: {
        get: false,
      },
      form: {
        range: [
          new Date(STARTING_DATE).toISOString().split('T')[0],
          new Date().toISOString().split('T')[0],
        ],
        groupBy: 'day',
      },
      datepicker: false,
      stats: [],
      currentGrouping: null,
    }
  },
  computed: {
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        hover: {
          mode: 'index',
          intersect: false,
        },
      }
    },
    labels() {
      return this.stats.map(i => this.formattedLabel(i))
    },
    // charts
    chartDataDepositWithdrawCount() {
      if (!this.stats) return []

      const datasets = [];

      ['countWithdraws', 'countDeposits'].forEach((key, i) => {
        datasets.push({
          label: key,
          backgroundColor: `${COLORS[i]}99`,
          borderColor: COLORS[i],
          data: this.stats.map(stats => stats?.[key] ?? 0),
        })
      })

      return datasets
    },
    chartDataDepositWithdrawValue() {
      if (!this.stats) return []

      const datasets = [];

      ['sumWithdrawn', 'sumDeposited'].forEach((key, i) => {
        datasets.push({
          label: key,
          backgroundColor: `${COLORS[i]}99`,
          borderColor: COLORS[i],
          data: this.stats.map(stats => (stats?.[key] ?? 0) / 100),
        })
      })

      return datasets
    },
    // chartDataFreeMoneyCount() {
    //   if (!this.stats) return []

    //   const datasets = [];

    //   ['countFaucetClaims', 'countGiftcodeClaims'].forEach((key, i) => {
    //     datasets.push({
    //       label: key,
    //       backgroundColor: `${COLORS[i + 6]}99`,
    //       borderColor: COLORS[i + 6],
    //       data: this.stats.map(stats => stats?.[key] ?? 0),
    //     })
    //   })

    //   return datasets
    // },
    chartDataFreeMoneySum() {
      if (!this.stats) return []

      const datasets = [];

      ['sumPromoClaims', 'sumAffiliateKickbacks'].forEach((key, i) => {
        datasets.push({
          label: key,
          backgroundColor: `${COLORS[i + 6]}99`,
          borderColor: COLORS[i + 6],
          data: this.stats.map(stats => ((stats?.[key] ?? 0) / 100).toFixed(2)),
        })
      })

      return datasets
    },
    chartDataBankSpread() {
      if (!this.stats) return []

      const datasets = [];

      ['bankSkinsback', 'bankBotItems', 'bankCsdeals', 'bankCrypto'].forEach((key, i) => {
        datasets.push({
          label: key,
          backgroundColor: `${COLORS[i]}99`,
          borderColor: COLORS[i],
          data: this.stats.map(stats => (stats?.[key] ?? 0).toFixed(2)),
          stack: 'stack',
        })
      })

      return datasets
    },
    chartDataBankSpreadPie() {
      return this.chartDataBankSpread.reduce((acc, bankType) => {
        acc.labels.push(bankType.label)
        acc.datasets[0].backgroundColor.push(bankType.backgroundColor)
        acc.datasets[0].borderColor.push(bankType.borderColor)
        acc.datasets[0].data.push(bankType.data[bankType.data.length - 1])

        return acc
      }, {
        datasets: [{
          backgroundColor: [],
          borderColor: [],
          data: [],
        }],
        labels: [],
      })
    },
    chartDataBankTotal() {
      if (!this.stats) return []

      return {
        label: 'bankTotal',
        backgroundColor: 'rgba(0,0,0,0)',
        borderColor: COLORS[4],
        data: this.stats.map(stats => (stats?.bankTotal ?? 0).toFixed(2)),
      }
    },
    chartDataUserBalances() {
      if (!this.stats) return []

      return {
        label: 'sumUserBalances',
        backgroundColor: `${COLORS[8]}00`,
        borderColor: COLORS[8],
        data: this.stats.map(stats => ((stats?.sumUserBalances ?? 0) / 100).toFixed(2)),
      }
    },
    chartDataUserItems() {
      if (!this.stats) return []

      return {
        label: 'sumUserItems',
        backgroundColor: `${COLORS[8]}00`,
        borderColor: COLORS[8],
        data: this.stats.map(stats => ((stats?.sumUserItems ?? 0) / 100).toFixed(2)),
      }
    },
    chartDataAffiliateBalances() {
      if (!this.stats) return []

      return {
        label: 'sumAffiliateBalances',
        backgroundColor: `${COLORS[9]}00`,
        borderColor: COLORS[9],
        data: this.stats.map(stats => ((stats?.sumAffiliateBalances ?? 0) / 100).toFixed(2)),
      }
    },
    chartDataBetsCount() {
      if (!this.stats) return []

      return {
        label: 'countBets',
        backgroundColor: `${COLORS[4]}99`,
        borderColor: COLORS[4],
        data: this.stats.map(stats => stats?.countBets ?? 0),
      }
    },
    chartDataBetsTotals() {
      if (!this.stats) return []

      const datasets = [];

      ['sumBet', 'sumWon', 'sumGameProfits'].forEach((key, i) => {
        datasets.push({
          label: key,
          backgroundColor: `${COLORS[i + 1]}99`,
          borderColor: COLORS[i + 1],
          data: this.stats.map(stats => (stats?.[key] ?? 0) / 100),
        })
      })

      return datasets
    },
    chartDataNewUsersCount() {
      if (!this.stats) return []

      const datasets = [];

      ['countNewUsers', 'countOnline'].forEach((key, i) => {
        datasets.push({
          label: key,
          backgroundColor: `${COLORS[i + 1]}99`,
          borderColor: COLORS[i + 1],
          data: this.stats.map(stats => stats?.[key] ?? 0),
        })
      })

      return datasets
    },
    // bets
    chartDataBetsSpread() {
      if (!this.stats) return []

      const games = [...new Set(this.stats.flatMap(s => Object.keys(s.betsByGame)))].sort()
      const { stats } = this

      const datasets = []

      games.forEach((game, i) => {
        datasets.push({
          label: game,
          backgroundColor: `${COLORS[i]}99`,
          borderColor: COLORS[i],
          data: stats.map(s => ((s?.betsByGame?.[game]?.sumBet ?? 0) / 100).toFixed(2)),
          stack: 'bets',
        })
      })

      return datasets
    },
    chartDataDepositsSpread() {
      if (!this.stats) return []

      const methods = [...new Set(this.stats.flatMap(s => Object.keys(s.depositsByMethod)))]
      const { stats } = this

      const datasets = []

      methods.forEach((key, i) => {
        datasets.push({
          label: key,
          backgroundColor: `${COLORS[i]}99`,
          borderColor: COLORS[i],
          data: stats.map(s => ((s?.depositsByMethod?.[key]?.sumDeposited ?? 0) / 100).toFixed(2)),
          stack: 'bets',
        })
      })

      return datasets
    },
    // aggregations
    avgGameProfits() {
      return this.stats.reduce((acc, stat) => acc + stat?.sumGameProfits ?? 0, 0) / this.stats.length / 100
    },
    avgGameProfitPerc() {
      const totalBet = this.stats.reduce((acc, stat) => acc + stat?.sumBet ?? 0, 0)
      const totalProfit = this.stats.reduce((acc, stat) => acc + stat?.sumGameProfits ?? 0, 0)

      return totalProfit / totalBet
    },
  },
  created() {
    window.vm = this

    this.getStats()
  },
  methods: {
    async getStats() {
      this.loading.get = true

      try {
        const { groupBy, range } = this.form

        const stats = await this.$socket.request('admin.stats.get', {
          start: range[0],
          end: range[1],
          groupBy,
        })

        this.stats = sortBy(stats.entries, 'id')
        this.currentGrouping = stats.grouping
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading.get = false
      }
    },
    formattedLabel({ start, end }) {
      const { currentGrouping } = this

      const startDate = new Date(start)
      const endDate = new Date(end)

      switch (currentGrouping) {
        case 'hour':
          return endDate.toLocaleString('default', {
            month: 'short', day: '2-digit', hour: '2-digit', timeZone: 'UTC',
          })
        case 'day':
          return endDate.toLocaleString('default', { month: 'short', day: 'numeric', timeZone: 'UTC' })
        case 'week':
          return [
            startDate.toLocaleString('default', { month: 'short', day: 'numeric', timeZone: 'UTC' }),
            endDate.toLocaleString('default', { month: 'short', day: 'numeric', timeZone: 'UTC' }),
          ].join(' - ')
        case 'month':
          return endDate.toLocaleString('default', { month: 'long', timeZone: 'UTC' })
        case 'year':
          return endDate.toLocaleString('default', { year: 'numeric', timeZone: 'UTC' })
        default:
          return endDate.toLocaleString('default', {
            year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC',
          })
      }
    },
    async onCopy(text) {
      try {
        await navigator.clipboard.writeText(text)
        this.$toast.info('You successfully have autism.')
      } catch (err) {
        this.$toast.error('Hey, fuck you, guy.')
      }
    },
  },
}
</script>

<style lang="scss" scoped>


.grouping-selector {
  flex: 1 1;
}
</style>
